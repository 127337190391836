"use client";

import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SidebarProfile from "./SidebarProfile";
import SidebarMenu from "./SidebarMenu";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import Image from "next/image";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from "@/app/_components/ui/dropdown";

export default function Sidebar({ user }: { user: any }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-1 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="w-full px-4 text-center">
                  <Image
                    className="mx-auto h-8 w-auto"
                    src={"/images/logoIcon.png"}
                    alt="Pathways"
                    width={40}
                    height={60}
                  />
                  <h1 className="text-center text-xl font-semibold text-white">
                    Pathways <span className="block text-xs">(Beta)</span>
                  </h1>
                </div>
                <div className="h-0 flex-1 overflow-y-auto px-2">
                  <nav className="mt-5 space-y-1 px-2">
                    <SidebarMenu
                      user={user}
                      setSidebarOpen={setSidebarOpen}
                      sidebarOpen={sidebarOpen}
                    />
                  </nav>
                </div>
                {/* <NotificationBell /> */}
                <SidebarProfile />
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden w-48 md:fixed md:inset-y-0 md:flex md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-indigo-700">
          <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
            <div className="flex flex-shrink-0 flex-col items-center justify-center px-4">
              <Image
                className="mx-auto h-8 w-auto"
                src={"/images/logoIcon.png"}
                alt="Pathways"
                width={40}
                height={60}
              />
              <h1 className="text-center text-xl font-semibold text-white">
                Pathways <span className="block text-xs">(Beta)</span>
              </h1>
            </div>
            <nav className="mt-5 flex-1 space-y-1 px-2">
              <SidebarMenu
                user={user}
                setSidebarOpen={setSidebarOpen}
                sidebarOpen={sidebarOpen}
              />
            </nav>
          </div>
          <div className="mt-5 px-2 py-4 text-center font-semibold hover:underline">
            <Dropdown>
              <DropdownButton plain className="!text-white">
                Pilot Questionnaire
                <ArrowRightIcon className="h-4 w-4 !text-white" />
              </DropdownButton>
              <DropdownMenu anchor="bottom end">
                <DropdownItem href="/feedback/1">Easy Read</DropdownItem>
                <DropdownItem href="/feedback/2">Standard</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <SidebarProfile />
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
}
