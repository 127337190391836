import { useState } from "react";
import Image from "next/image";

interface ImageUploadProps {
  onImageSelect?: (image: File) => void;
}

export default function ImageUpload({ onImageSelect }: ImageUploadProps) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setSelectedImage(file);
      if (onImageSelect) {
        onImageSelect(file);
      }
    } else {
      alert("Please select a PNG or JPG image file.");
    }
  };

  return (
    <div className="mt-4 flex items-center">
      {selectedImage ? (
        <Image
          src={URL.createObjectURL(selectedImage)}
          alt="Selected"
          className="h-12 w-12 rounded-full object-cover"
          width="48"
          height="48"
        />
      ) : (
        <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
          <svg
            className="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
      <label
        htmlFor="profile-photo"
        className="ml-5 cursor-pointer rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {selectedImage ? "Change" : "Select Image"}
      </label>
      <input
        id="profile-photo"
        name="profile-photo"
        type="file"
        accept=".png,.jpg,.jpeg"
        className="hidden"
        onChange={handleFileSelect}
      />
    </div>
  );
}
